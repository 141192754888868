<template>
  <div
    class="skill"
    :class="[type, clickable ? 'clickable' : '', active ? 'active' : '']"
  >
    {{ title }}
  </div>
</template>

<script>
import "./SkillComponent.scss";

export default {
  name: "SkillComponent",

  props: {
    title: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    clickable: {
      type: Boolean,
      required: false,
    },

    active: {
      type: Boolean,
      required: false,
    },
  },
};
</script>