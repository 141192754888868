<template>
  <div class="navbar-container">
    <div class="navbar-content">
      <div class="navbar-header">
        <img class="navbar-image" src="/profile.png" alt="Profile Image" />
        <h1 class="navbar-title">Casper Stybert</h1>
        <h2 class="navbar-job-title">Senior Software Developer</h2>
      </div>
      <div class="menu">
        <h3
          class="menu-item"
          :class="{ active: activeSection == 'about' }"
          @click="scrollToElement('about')"
        >
          About
        </h3>
        <h3
          class="menu-item"
          :class="{ active: activeSection == 'skills' }"
          @click="scrollToElement('skills')"
        >
          Skills
        </h3>
        <h3
          class="menu-item"
          :class="{ active: activeSection == 'experience' }"
          @click="scrollToElement('experience')"
        >
          Experience
        </h3>
        <h3
          class="menu-item"
          :class="{ active: activeSection == 'education' }"
          @click="scrollToElement('education')"
        >
          Education
        </h3>
      </div>
      <div class="navbar-links">
        <a
          class="link"
          href="https://www.linkedin.com/in/casper-stybert/"
          target="_blank"
        >
          <font-awesome-icon class="link-icon" :icon="['fab', 'linkedin']" />
        </a>
        <a class="link" href="mailto:cstybert@gmail.com" target="_blank">
          <font-awesome-icon class="link-icon" :icon="['fa', 'envelope']" />
        </a>
        <!-- <a class="link" href="https://github.com/cstybert" target="_blank">
          <font-awesome-icon class="link-icon" :icon="['fab', 'github']" />
        </a> -->
        <a class="link" href="resume.pdf" target="_blank">
          <font-awesome-icon class="link-icon" :icon="['fa', 'file-pdf']" />
        </a>
      </div>
      <div class="spacer-container">
        <div class="spacer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "./NavbarComponent.scss";
export default {
  name: "NavbarComponent",

  data() {
    return {
      activeSection: "about",
    };
  },

  computed: {
    sectionIds() {
      const sectionElements = document.querySelectorAll(".section-component");
      return Array.from(sectionElements).map((section) => section.id);
    },
  },

  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    onScroll() {
      function activateSection(sectionId) {
        const section = document.getElementById(sectionId);

        const rect = section.getBoundingClientRect();
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const halfwayPoint = viewportHeight * 0.33;

        return (
          rect.bottom >= 0 &&
          rect.top <= viewportHeight &&
          !(rect.top < halfwayPoint && rect.bottom < 0) &&
          !(rect.top > halfwayPoint && rect.top > halfwayPoint)
        );
      }

      if (window.scrollY == 0) {
        this.setActiveSection(this.sectionIds[0]);
      } else {
        this.sectionIds.find((sectionId) => {
          if (activateSection(sectionId)) {
            this.setActiveSection(sectionId);
          }
        });
      }
    },

    scrollToElement(sectionId) {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        const sectionPosition =
          sectionElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = sectionPosition - 75;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },

    setActiveSection(sectionId) {
      this.activeSection = sectionId;
    },
  },
};
</script>