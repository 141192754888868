<template>
  <NavbarComponent />
  <router-view />
</template>

<script>
import "./App.scss";
import NavbarComponent from "@/components/navbar/NavbarComponent.vue";

export default {
  name: "App",

  components: {
    NavbarComponent,
  },

  mounted() {
    if (!document.documentElement.getAttribute("data-theme")) {
      document.documentElement.setAttribute("data-theme", "dark");
    }
  },
};
</script>